import React, { useEffect, useState } from "react";
import {
	useParsedQuery,
	createSearchParams,
	useAbsolutePath
} from "@ploy-lib/routing";
import { Field, useCalculationFields } from "@ploy-lib/calculation";
import { identityRecordOfFieldEditorOptions } from "@ploy-ui/form-fields";
import {
	ButtonFieldProps,
	commonButtonColors,
	commonButtonVariants
} from "./ButtonCommon";
import { ButtonField } from "./Button";
import icons from "./Icons";
import { apiResourceUrl } from "@ploy-lib/core";
import { useAppLoad } from "../../appLoadContext";
import { useGTMExtended } from "../../thirdPartyScripts/GoogleTagManagerHandler";

const fields: readonly (Field & { formName: string })[] = [
	{
		namespace: "Object",
		name: "CategoryCombined",
		formName: "categoryCombined"
	},
	{
		namespace: "Object",
		name: "ObjectTypeId",
		formName: "objectTypeId"
	},
	{
		namespace: "Object",
		name: "ObjectSubTypeId",
		formName: "objectSubTypeId"
	},
	{
		namespace: "Object",
		name: "ObjectCondition",
		formName: "objectCondition"
	},
	{
		namespace: "Calculator",
		name: "ObjectPrice",
		formName: "objectPrice"
	},
	{
		namespace: "Calculator",
		name: "ObjectPriceExVat",
		formName: "objectPriceExVat"
	},
	{
		namespace: "Calculator",
		name: "EquityPercent",
		formName: "equityPercent"
	},
	{
		namespace: "Calculator",
		name: "TotalTermsCount",
		formName: "termsCount"
	},
	{
		namespace: "Calculator",
		name: "TotalTermsCountInYears",
		formName: "totalTermsCountInYears"
	}
];

export const NewTabButton = ({
	muiEndIcon,
	pageToOpen,
	redirectPathKey,
	...rest
}: ButtonFieldProps) => {
	const { ext: queryParams } = useParsedQuery<{
		ext: Record<string, string>;
	}>();
	const pushGTMEvent = useGTMExtended();
	const absolutePath = useAbsolutePath("..");
	const appLoadData = useAppLoad();

	const calculationFields = useCalculationFields(
		...fields.map(f => ({ name: f.name, namespace: f.namespace }))
	);

	const [newQueryParams, setNewQueryParams] = useState<Record<string, any>>();
	const [urlWithNextPage, setUrlWithNextPage] = useState<string>();

	useEffect(() => {
		const fetchNextPageUrl = async () => {
			const { ext: fieldValues } = calculationFields.reduce<{
				ext: Record<string, string>;
			}>(
				(acc, field, index) => {
					const value =
						field?.value?.toString() ?? field?.fieldValue?.toString();
					if (typeof value !== "undefined")
						acc.ext[fields[index].formName] = value;
					return acc;
				},
				{ ext: {} }
			);

			// Handle categoryCombined vs objectTypeId vs objectSubTypeId
			if (
				NoValue(fieldValues.objectTypeId) &&
				!NoValue(fieldValues.categoryCombined)
			) {
				const categoryCombined = fieldValues.categoryCombined.split("|");
				if (categoryCombined.length === 2) {
					// categoryCombined = {objectSubTypeId}|{objectTypeId}
					fieldValues.objectTypeId = categoryCombined[1];
					fieldValues.objectSubTypeId = categoryCombined[0];
				}
			}

			const newQueryParams = {
				ext: { ...queryParams, ...fieldValues }
			};

			let urlWithNextPage: string;

			if (redirectPathKey && redirectPathKey.length > 0) {
				const response = await fetch(
					apiResourceUrl(
						`/NewTabButton/GetBasePath?key=${redirectPathKey}&product=${appLoadData.productExternalCode}`
					),
					{ method: "GET" }
				);

				if (!response.ok) throw new Error("En feil har oppstått.");

				const content = (await response.json()) as {
					pathKey?: string;
					basePath?: string;
				};

				if (!content.basePath) throw new Error("En feil har oppstått.");

				if (pageToOpen) newQueryParams.ext["pageToOpen"] = pageToOpen;

				urlWithNextPage = content.basePath;
			} else urlWithNextPage = `${absolutePath}/${pageToOpen}`;

			setNewQueryParams(newQueryParams);
			setUrlWithNextPage(urlWithNextPage);
		};

		fetchNextPageUrl();
	}, [
		pageToOpen,
		redirectPathKey,
		queryParams,
		calculationFields,
		appLoadData.productExternalCode,
		absolutePath
	]);

	const onClick = async () => {
		window.open(
			`${urlWithNextPage}?${createSearchParams(newQueryParams)}`,
			"_blank"
		);
		pushGTMEvent("add_to_cart");
	};

	return (
		<ButtonField
			{...rest}
			redirectPathKey={redirectPathKey}
			endIcon={muiEndIcon && icons[muiEndIcon]}
			pageToOpen={pageToOpen}
			onClick={onClick}
		/>
	);
};

function NoValue(val: any): boolean {
	return val === undefined || val === null || val === "";
}

NewTabButton.displayName = "NewTabButton";

export const EditorNewTabButton = identityRecordOfFieldEditorOptions({
	NewTabButton: {
		editableOptions: {
			variant: commonButtonVariants,
			color: commonButtonColors,
			pageToOpen: true,
			redirectPathKey: true,
			muiEndIcons: Object.keys(icons)
		}
	}
});
